var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "imelink-translate-setting" },
    [
      _c(
        "language-select",
        {
          attrs: { displaySwitch: true, mystyle: "edit-language-select" },
          on: { change: _vm._handleChange },
          model: {
            value: _vm.$store.state.imStore.currentLanguageEnvironment,
            callback: function($$v) {
              _vm.$set(
                _vm.$store.state.imStore,
                "currentLanguageEnvironment",
                $$v
              )
            },
            expression: "$store.state.imStore.currentLanguageEnvironment"
          }
        },
        [
          _c("div", { attrs: { title: _vm.$t("language") } }, [
            this.$store.state.imStore.otherPartyInfo.characterConvertStatus
              ? _c("span", { staticClass: "imelink-editor__action-txt" }, [
                  _vm._v(_vm._s(_vm.message))
                ])
              : _c("i", { staticClass: "iconfont_Me icon-a-gundongzu33" })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }