var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.$store.state.imStore.copyLinkDialogVisible,
        "custom-class": "copyLinkDialog",
        center: "",
        width: "420px",
        "show-close": false,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "before-close": _vm.beforeClose,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.imStore,
            "copyLinkDialogVisible",
            $event
          )
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_c("i", { staticClass: "el-icon-s-promotion" })]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.webLoading,
              expression: "webLoading"
            }
          ],
          staticClass: "copyimage",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _c(
            "label",
            { staticStyle: { display: "flex", width: "90%" } },
            [
              _c("el-checkbox", {
                on: { change: _vm.changeImg },
                model: {
                  value: _vm.checkedImg,
                  callback: function($$v) {
                    _vm.checkedImg = $$v
                  },
                  expression: "checkedImg"
                }
              }),
              _vm.copyLinkData.type === "image"
                ? _c("img", { attrs: { src: _vm.copyLinkData.text } })
                : _vm._e(),
              _vm.copyLinkData.type === "video"
                ? _c("video", { attrs: { src: _vm.copyLinkData.text } })
                : _vm._e(),
              _vm.copyLinkData.type === "webUrl"
                ? _c("div", { staticClass: "url-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "image-box" },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "fileIcon",
                            attrs: {
                              fit: "cover",
                              src: _vm.jsonUrlInfos
                                ? _vm.jsonUrlInfos.image
                                : ""
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "error-img",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", {
                                  staticClass: "iconfont_Me icon-link"
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "url-infos" }, [
                      _c("div", { staticClass: "url-title ellipsis2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.jsonUrlInfos
                                ? _vm.jsonUrlInfos.title
                                : "title"
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("div", { staticClass: "url-content ellipsis2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.jsonUrlInfos
                                ? _vm.jsonUrlInfos.content
                                : "content"
                            ) +
                            "\n          "
                        )
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "copyText" }, [
        _c(
          "label",
          { staticStyle: { display: "flex", width: "100%" } },
          [
            _c("el-checkbox", {
              on: { change: _vm.changeTxt },
              model: {
                value: _vm.checkedTxt,
                callback: function($$v) {
                  _vm.checkedTxt = $$v
                },
                expression: "checkedTxt"
              }
            }),
            _c("div", { staticClass: "text" }, [
              _vm._v("\n        " + _vm._s(_vm.copyLinkData.text) + "\n      ")
            ])
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", {
            staticClass:
              "el-button el-button--default el-button--small el-icon-close",
            on: { click: _vm.closeDilog }
          }),
          _c("el-button", {
            staticClass:
              "el-button el-button--default el-button--small el-button--primary el-icon-check",
            attrs: { disabled: _vm.disable },
            on: { click: _vm.checkDilog }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }