<template>
  <div class="imelink-translate-setting">
    <language-select
      v-model="$store.state.imStore.currentLanguageEnvironment"
      @change="_handleChange"
      :displaySwitch="true"
      mystyle="edit-language-select"
    >
      <div :title="$t('language')">
        <span
          v-if="this.$store.state.imStore.otherPartyInfo.characterConvertStatus"
          class="imelink-editor__action-txt"
          >{{ message }}</span
        >
        <i v-else class="iconfont_Me icon-a-gundongzu33"></i>
      </div>
    </language-select>
  </div>
</template>

<script>
export default {
  name: "TranslateSetting",
  inject: ["control"],
  mounted() {
    this.handlerSetMessage();
  },
  computed: {
    message: {
      get() {
        const languages = this.getGlobalLanguages();
        const message = languages[this.alialocale]["langs"][this.alialocale];
        return message;
      },
      set() {},
    },
    translateUser() {
      if (this.control.currentContact.isGroup) {
        const { proxyUserId } = this.groupInfo.translate;
        if (proxyUserId) {
          return (
            this.groupInfo.members.find((item) => item.id == proxyUserId) || {}
          );
        }
      }
      return {};
    },
    groupInfo() {
      return this.control.getCurrentGroupInfo();
    },
    alialocale() {
      return this.$store.state.imStore.currentLanguageEnvironment;
    },
  },
  methods: {
    handlerSetMessage() {
      const languages = this.getGlobalLanguages();
      this.message = languages[this.alialocale]["langs"][this.alialocale];
    },
    _handleChange(data) {
      data.contact = this.control.currentContact;
      if (data.contact.isGroup) {
        this.groupInfo.translate.target = data.encode;
      }
      this.control.$emit("change-translate-lang", data, {
        success: () => {},
        error: () => {},
      });
      localStorage.setItem("imSelectCode", data.code);
    },
  },
};
</script>

<style lang="stylus">
@import '~styles/utils/index';

+b(imelink-translate-setting) {
  display: flex;
  align-items: center;
  justify-content: center
  img {
    vertical-align: middle;
  }

  .me-avatar {
    margin-left: 10px;
    position: relative;
    top: 2px;
  }

  .me-avatar__error {
    font-size: 18px;
    position: relative;
    top: -2px;
  }
}
</style>
