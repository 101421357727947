var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "container", staticClass: "imelink-editor" },
    [
      _c("div", { ref: "resize", staticClass: "imelink-editor__resize" }),
      _c("div", { staticClass: "imelink-editor__header" }, [
        this.control.currentContact.isRobotChat
          ? _c("div", { staticClass: "imelink-editor__headerleft" }, [
              _c("span", { staticClass: "remainingTimes" }, [
                _c("span", { staticClass: "count" }, [
                  _vm._v(
                    _vm._s(
                      _vm.robotInfo && _vm.robotInfo.validNums
                        ? _vm.robotInfo.validNums
                        : 0
                    )
                  )
                ]),
                _c("span", { staticClass: "unit" }, [
                  _vm._v(_vm._s(_vm.robotInfo.prompts))
                ]),
                _c("i", {
                  staticClass: "iconStyle iconfont_Me icon-addition",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.openRobotNotice.apply(null, arguments)
                    }
                  }
                })
              ])
            ])
          : _c(
              "div",
              { staticClass: "imelink-editor__headerleft" },
              [
                _c(
                  "emotion",
                  {
                    attrs: { width: "400" },
                    on: { choose: _vm._handEmoChoose }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "imelink-editor__action",
                        attrs: { title: _vm.$t("emoji") }
                      },
                      [_c("i", { staticClass: "el-icon--smile" })]
                    )
                  ]
                ),
                _c(
                  "el-upload",
                  {
                    ref: "uploader",
                    attrs: {
                      action: "",
                      multiple: "",
                      "http-request": _vm.upload,
                      "show-file-list": false
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "imelink-editor__action",
                        attrs: { title: _vm.$t("sendFile") }
                      },
                      [_c("i", { staticClass: "el-icon-paperclip" })]
                    )
                  ]
                ),
                this.control.currentContact.isUser
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "imelink-editor__action",
                          attrs: { title: _vm.$t("transferAccount") },
                          on: { click: _vm._handleAccount }
                        },
                        [_c("i", { staticClass: "el-icon--moneycollect" })]
                      )
                    ]
                  : _vm._e(),
                _c("ImelinkTranslateSetting", {
                  staticClass:
                    "imelink-editor__action imelink-editor__action--right"
                }),
                [
                  _vm.control.currentContact.id !==
                  _vm.$store.getters.userInfo.id
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          staticClass:
                            "imelink-editor__action multimediaBox mk_button",
                          attrs: { title: _vm.$t("videoConference") },
                          on: { click: _vm._handleAudioAndVideo }
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont_Me icon-video fs22"
                          })
                        ]
                      )
                    : _vm._e()
                ]
              ],
              2
            ),
        _c("div", { staticClass: "imelink-editor__headerright" }, [
          _c(
            "div",
            { staticClass: "imelink-editor__submit" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    disabled: _vm.$store.state.imStore.submitDisabled,
                    size: "medium",
                    type:
                      _vm.$store.state.imStore.otherPartyInfo.encryptStatus ===
                        1 && _vm.$store.getters.userInfo.vipStatus
                        ? "encring"
                        : "warning"
                  },
                  on: {
                    click: function($event) {
                      return _vm.send()
                    }
                  }
                },
                [
                  _vm.$store.state.imStore.otherPartyInfo.encryptStatus === 1 &&
                  _vm.$store.getters.userInfo.vipStatus
                    ? _c("i", {
                        staticClass: "iconfont_Me icon-a-zu10328",
                        staticStyle: { "margin-right": "3px" }
                      })
                    : _c("i", { staticClass: "el-icon-s-promotion" }),
                  _vm._v("\n          Ctrl + Enter\n        ")
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "imelink-editor__main" }, [
        _c("div", {
          ref: "textarea",
          staticClass: "imelink-editor__textarea",
          attrs: { contenteditable: "true" },
          on: {
            keyup: function($event) {
              if (
                $event.ctrlKey ||
                $event.shiftKey ||
                $event.altKey ||
                $event.metaKey
              )
                return null
              return _vm._inputListen.apply(null, arguments)
            },
            blur: _vm._inputBlur,
            input: _vm.input,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              if (!$event.ctrlKey) return null
              return _vm.send()
            },
            paste: _vm._handlePaste,
            dragenter: function($event) {
              return _vm._dragenter($event)
            },
            dragover: function($event) {
              return _vm._ondragover($event)
            },
            dragleave: _vm._dragleave,
            drop: function($event) {
              return _vm._drop($event)
            }
          }
        })
      ]),
      _c("div", { staticClass: "imelink-editor__footer" }),
      _c("el-popover", {
        attrs: {
          placement: "top",
          "popper-class": "atPopover",
          trigger: "manual"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return _vm._l(_vm.members, function(item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "flex flex_sb bb p8 imelink-popover-list",
                    staticStyle: { "max-width": "270px" },
                    on: {
                      click: function($event) {
                        return _vm.select(item)
                      }
                    }
                  },
                  [
                    _c("avatar", {
                      attrs: {
                        src: item.avatar,
                        "error-icon": item.isGroup ? "el-icon--group1" : "",
                        size: "28px",
                        "border-radius": "50%"
                      }
                    }),
                    _c(
                      "span",
                      {
                        style: {
                          overflow: "hidden",
                          "white-space": "nowrap",
                          "text-overflow": "ellipsis",
                          width: "100%"
                        },
                        attrs: { title: item.displayName }
                      },
                      [_vm._v(_vm._s(item.displayName))]
                    )
                  ],
                  1
                )
              })
            },
            proxy: true
          },
          {
            key: "reference",
            fn: function() {
              return [
                _c("div", {
                  staticClass: "imelink-editor__textarea",
                  staticStyle: { display: "none" }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.state.visible,
          callback: function($$v) {
            _vm.$set(_vm.state, "visible", $$v)
          },
          expression: "state.visible"
        }
      }),
      _c("CopyLinkDialog", {
        on: { confirmCopyLinkDialog: _vm.confirmCopyLinkDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }