<!-- 内容审核操作弹窗 -->
<template>
  <el-dialog
    :visible.sync="$store.state.imStore.copyLinkDialogVisible"
    custom-class="copyLinkDialog"
    center
    width="420px"
    :show-close="false"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :before-close="beforeClose"
    :destroy-on-close="true"
  >
    <span slot="title" class="dialog-title">
      <i class="el-icon-s-promotion" />
    </span>
    <div
      class="copyimage"
      v-loading="webLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <label style="display:flex;width: 90%;">
        <el-checkbox v-model="checkedImg" @change="changeImg"></el-checkbox>
        <img v-if="copyLinkData.type === 'image'" :src="copyLinkData.text" />
        <video
          v-if="copyLinkData.type === 'video'"
          :src="copyLinkData.text"
        ></video>
        <div class="url-wrap" v-if="copyLinkData.type === 'webUrl'">
          <div class="image-box">
            <el-image
              class="fileIcon"
              fit="cover"
              :src="jsonUrlInfos ? jsonUrlInfos.image : ''"
            >
              <div slot="error" class="error-img">
                <i class="iconfont_Me icon-link"></i>
              </div>
            </el-image>
          </div>
          <div class="url-infos">
            <div class="url-title ellipsis2">
              {{ jsonUrlInfos ? jsonUrlInfos.title : "title" }}
            </div>
            <div class="url-content ellipsis2">
              {{ jsonUrlInfos ? jsonUrlInfos.content : "content" }}
            </div>
          </div>
        </div>
      </label>
    </div>
    <div class="copyText">
      <label style="display:flex;width: 100%;">
        <el-checkbox v-model="checkedTxt" @change="changeTxt"></el-checkbox>
        <div class="text">
          {{ copyLinkData.text }}
        </div>
      </label>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        class="el-button el-button--default el-button--small el-icon-close"
        @click="closeDilog"
      ></el-button>
      <el-button
        class="el-button el-button--default el-button--small el-button--primary el-icon-check"
        :disabled="disable"
        @click="checkDilog"
      ></el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapMutations } from "vuex";
import { getPageCrawl } from "@/api/newVersion/publishArticle/index.js";
export default {
  data() {
    return {
      checkedImg: true,
      checkedTxt: false,
      disable: false,
      webLoading: false,
      jsonUrlInfos: null,
    };
  },
  computed: {
    copyLinkData() {
      return this.$store.state.imStore.copyLinkData;
    },
  },
  watch: {
    "$store.state.imStore.copyLinkDialogVisible": {
      async handler(val, old) {
        if (!!val && val != old) {
          if (this.copyLinkData.type == "webUrl") {
            // this.webLoading = true;
            // await this.getWebInfo();
            // this.webLoading = false;
          }
        } else {
          this.disable = false;
          this.checkedImg = true;
          this.checkedTxt = false;
          this.webLoading = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setCopyLinkDialogVisible"]),
    async getWebInfo() {
      return new Promise((resolve, reject) => {
        getPageCrawl([this.copyLinkData.text])
          .then((result) => {
            if (result.code == 200) {
              const pageCrawlContent = result.data.data;
              this.jsonUrlInfos = pageCrawlContent[this.copyLinkData.text];
            } else {
              this.$message({
                type: "error",
                message: result.message,
              });
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    beforeClose() {
      this.setCopyLinkDialogVisible(false);
    },
    closeDilog() {
      this.setCopyLinkDialogVisible(false);
    },
    checkDilog() {
      this.$emit("confirmCopyLinkDialog", {
        checkedImg: this.checkedImg,
        checkedTxt: this.checkedTxt,
      });
      this.setCopyLinkDialogVisible(false);
    },
    changeImg() {
      if (!this.checkedTxt && !this.checkedImg) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    changeTxt() {
      if (!this.checkedTxt && !this.checkedImg) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~styles/utils/index';
.copyLinkDialog
  .url-wrap
    display: flex;
    align-items: center;
    padding: 14px 15px;
    background: #c6edcc;
    width: 100%;
    border-radius: 6px;
    .image-box
      width: 60px;
      height: 58px;
      margin-right: 15px;
      .fileIcon
        width: 60px;
        height: 58px;
    .url-title
      font-weight: 700;
      color: #1a051d;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 2px;
    .url-content
      font-size: 12px;
      color: #666666;
      line-height: 16px;
    .ellipsis2
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
    .error-img
      width: 100%;
      height 100%;
      display:flex;
      justify-content:center;
      align-items:center;
      background: #fff;
      i
        font-size: 45px;
        color:#999
  .copyimage,
  .copyText
    display: flex;
    :deep .el-radio
      margin-right: 10px;
    img,
    video
      flex: 1;
      max-height: 300px;
      max-width: 100%;
      object-fit: cover;
      border-radius: 2px;
    .text
      word-break: break-all;
      width: 100%;
  .copyText
    margin-top: 10px;
    .text
      cursor: pointer;
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-light();
</style>
<style lang="stylus">
.copyLinkDialog
  z-index: 2004 !important;
  .copyimage,
  .copyText
    .el-radio
      margin-right: 10px;
  .el-icon-s-promotion
    font-size: 30px ;
    color: #606266 ;
  .el-checkbox
    margin-right: 20px;
    .el-checkbox__inner
      border-radius: 50%;
      width: 18px;
      height: 18px;
      &::after
        left: 6px;
        width: 4px;
        height: 10px;
</style>
