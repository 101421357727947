<template>
  <div class="imelink-editor" ref="container">
    <div class="imelink-editor__resize" ref="resize"></div>
    <div class="imelink-editor__header">
      <div
        v-if="this.control.currentContact.isRobotChat"
        class="imelink-editor__headerleft"
      >
        <span class="remainingTimes">
          <span class="count">{{
            robotInfo && robotInfo.validNums ? robotInfo.validNums : 0
          }}</span>
          <span class="unit">{{ robotInfo.prompts }}</span>
          <i
            class="iconStyle iconfont_Me icon-addition"
            @click.stop="openRobotNotice"
          ></i>
        </span>
      </div>
      <div class="imelink-editor__headerleft" v-else>
        <emotion width="400" @choose="_handEmoChoose">
          <div class="imelink-editor__action" :title="$t('emoji')">
            <i class="el-icon--smile"></i>
          </div>
        </emotion>
        <el-upload
          action
          ref="uploader"
          multiple
          :http-request="upload"
          :show-file-list="false"
        >
          <div class="imelink-editor__action" :title="$t('sendFile')">
            <i class="el-icon-paperclip"></i>
          </div>
        </el-upload>
        <template v-if="this.control.currentContact.isUser">
          <div
            class="imelink-editor__action"
            :title="$t('transferAccount')"
            @click="_handleAccount"
          >
            <i class="el-icon--moneycollect"></i>
          </div>
        </template>
        <!-- 选择语言 -->
        <ImelinkTranslateSetting
          class="imelink-editor__action imelink-editor__action--right"
        ></ImelinkTranslateSetting>
        <!-- 这里注释掉即时通讯的入口，禁止发起即时通讯 -->
        <template>
          <button
            class="imelink-editor__action multimediaBox mk_button"
            :title="$t('videoConference')"
            @click="_handleAudioAndVideo"
            v-preventReClick
            v-if="control.currentContact.id !== $store.getters.userInfo.id"
          >
            <!-- <div class="multimediaIcon"></div> -->
            <i class="iconfont_Me icon-video fs22"></i>
          </button>
        </template>
      </div>
      <div class="imelink-editor__headerright">
        <div class="imelink-editor__submit">
          <el-button
            round
            :disabled="$store.state.imStore.submitDisabled"
            @click="send()"
            size="medium"
            :type="
              $store.state.imStore.otherPartyInfo.encryptStatus === 1 &&
              $store.getters.userInfo.vipStatus
                ? 'encring'
                : 'warning'
            "
          >
            <!-- 加密消息 -->
            <i
              v-if="
                $store.state.imStore.otherPartyInfo.encryptStatus === 1 &&
                  $store.getters.userInfo.vipStatus
              "
              class="iconfont_Me icon-a-zu10328"
              style="margin-right:3px"
            ></i>
            <i v-else class="el-icon-s-promotion"></i>
            Ctrl + Enter
          </el-button>
        </div>
      </div>
    </div>
    <div class="imelink-editor__main">
      <div
        @keyup.exact="_inputListen"
        @blur="_inputBlur"
        @input="input"
        @keydown.ctrl.enter="send()"
        @paste="_handlePaste"
        @dragenter="_dragenter($event)"
        @dragover="_ondragover($event)"
        @dragleave="_dragleave"
        @drop="_drop($event)"
        class="imelink-editor__textarea"
        ref="textarea"
        contenteditable="true"
      ></div>
    </div>
    <div class="imelink-editor__footer"></div>
    <el-popover
      placement="top"
      v-model="state.visible"
      popper-class="atPopover"
      trigger="manual"
    >
      <template #default>
        <div
          v-for="item in members"
          :key="item.id"
          class="flex flex_sb bb p8 imelink-popover-list"
          @click="select(item)"
          style="max-width:270px"
        >
          <avatar
            :src="item.avatar"
            :error-icon="item.isGroup ? 'el-icon--group1' : ''"
            :size="'28px'"
            :border-radius="'50%'"
          ></avatar>
          <span
            :title="item.displayName"
            :style="{
              overflow: 'hidden',
              'white-space': 'nowrap',
              'text-overflow': 'ellipsis',
              width: '100%',
            }"
            >{{ item.displayName }}</span
          >
        </div>
      </template>
      <template #reference>
        <div class="imelink-editor__textarea" style="display: none"></div>
      </template>
    </el-popover>
    <CopyLinkDialog @confirmCopyLinkDialog="confirmCopyLinkDialog" />
  </div>
</template>

<script>
import ImelinkTranslateSetting from "./translate-setting";
import CopyLinkDialog from "@/newVersion/components/copyLinkDialog";
import { fileToBase64, fileToURL } from "@/utils";
import * as openpgp from "openpgp";
import { vipUserStatus } from "@/api/newVersion/vip";
import { toBytesSize } from "@/utils/";
import { ArrayBufferToWordArray, AESEncData, AESIv, AESKey } from "@/utils/aes";
import UUID from "uuidjs";
import Bus from "@/utils/bus.js";
import isURL from "validator/lib/isURL";
import isEmail from "validator/lib/isEmail";
const className = "__at_span";
export default {
  name: "Editor",
  inject: ["control"],
  data() {
    this.timeout = 8000;
    this.timeoutTimer = {};
    this.prevCursorPosition = 0;
    return {
      dropEvent: null,
      state: {
        send: "",
        inputing: "", // 实时输入内容
        focusNode: {}, // 缓存光标所在节点
        focusOffset: 0, // 缓存光标所在位置
        inputed: false, // 输入框内容是否是常规输入
        visible: false, // 是否显示选择人员弹窗
        observer: {}, // dom 监听器
        atIds: [], // @ 的人员id
      },
      checkedImg: true,
      checkedText: false,
      modeIsAt: false,
      _modeIsdrop: false,
      members: [],
      limitIndex: 0,
    };
  },
  props: {
    curUser: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meetingAddress: {
      type: String,
    },
  },
  components: {
    ImelinkTranslateSetting,
    CopyLinkDialog,
  },
  mounted() {
    let self = this;
    this.keyDown();
    window.addEventListener("drop", this.handlerAddListenerEvent);
    let startY;
    let height;
    const mousemoveEnent = (e) => {
      e.preventDefault();
      if (height === undefined) height = this.$refs.container.offsetHeight;
      //startY = startY - e.clientY;
      const offsetY = startY - e.clientY;
      this.$refs.container.style.height = `${height + offsetY}px`;
    };
    this.$refs.resize.addEventListener("mousedown", (e) => {
      e.preventDefault();
      startY = e.clientY;
      document.body.addEventListener("mousemove", mousemoveEnent);
      document.body.addEventListener("mouseup", () => {
        document.body.removeEventListener("mousemove", mousemoveEnent);
        try {
          height = this.$refs.container.offsetHeight;
        } catch (e) {}
      });
    });
    Bus.$on("qsData", function(msg) {
      self.send(msg.question);
      self.control.scrollToBottom();
    });
  },
  beforeDestroy() {
    window.removeEventListener("drop", this.handlerAddListenerEvent);
    window.removeEventListener("click", this.close);
    Bus.$off("qsData");
  },
  computed: {
    robotInfo() {
      return this.$store.state.robot.robotInfo;
    },
    secretChatStatus() {
      return this.$store.state.imStore.otherPartyInfo.encryptStatus === 1
        ? true
        : false;
    },
  },
  watch: {
    "state.visible": {
      handler(val) {
        if (val) {
          window.addEventListener("click", this.close);
        } else {
          window.removeEventListener("click", this.close);
        }
      },
      deep: true,
    },
    "$store.state.imStore.editorSetInputValue": {
      handler(val, old) {
        if (val && val != old) {
          this.setInput(val);
          this.$store.commit("setEditorSetInputValue", null);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 打开机器人聊天次数简介
    openRobotNotice() {
      this.securityVerification(async () => {
        this.$store.commit("setRobotChatDescription", {
          visible: true,
          type: "buyQuestions",
        });
      });
    },
    handlerAddListenerEvent(e) {
      e.preventDefault();
      Array.from(e.dataTransfer.files).forEach((file) => {
        this.upload({
          file,
        });
      });
    },
    _handleAccount() {
      this.$store.commit("setReloadPage", false);
      this.$store.commit("setTransferId", this.control.currentContact.id);
      this.$store.commit("setTransferType", "transfer");
      this.jumpPage({ name: "wallet" });
      // 此处是为了触发钱包页面的created生命周期事件
      this.$nextTick((e) => {
        this.$store.commit("setReloadPage", true);
      });
      this.$store.commit("setIMWindowSize", "mini");
    },

    async _handleAudioAndVideo() {
      /** 5.2.3走会议流程，这里触发父组件的开启会议室流程  */
      this.$emit("openMeetingRoom", "isMeet2021");
    },
    async _handleOpenCreateGroup() {
      const { currentContact } = this.control;
      this.control.openSelection("creategroup");
      const component = this.control.$refs.contactList[0];
      await component.$nextTick();
      component.changeSelected(true, currentContact);
    },
    //清空编辑器
    clear() {
      this.$refs.textarea.innerHTML = " ";
    },
    //清空编辑器
    setInput(val) {
      // 将换行替换为html换行标签
      let htmlText = val.replace(/\n/g, "<br>");
      this.$refs.textarea.innerHTML = htmlText;
    },
    _onComplet() {
      this.$refs.uploader.uploadFiles = [];
      /**
      return {
        success() {
          onComplet();
        },
        error() {
          onComplet();
        }
      };
       */
    },
    _bindAutoTimeout(messageId, callback) {
      this._clearAutoTimeout(messageId);
      this.timeoutTimer[messageId] = setTimeout(callback, this.timeout);
    },
    _clearAutoTimeout(messageId) {
      this.timeoutTimer[messageId] &&
        clearTimeout(this.timeoutTimer[messageId]);
    },
    _changeStatus(id, status, contact) {
      this.control.updateMessage({ id: id, status: `send_${status}` });
      if (contact) {
        this.control.addRecordContact(contact);
      }
    },
    // 将消息插入聊天列表队列
    // 在这里判断用户是否开启只有好友可以聊天的功能，并拦截
    async _previewSend(message = {}) {
      const sendTime = await this.getRealTime();
      message = {
        ...{
          id: UUID.generate(),
          status: "send_going",
          fromUser: this.control.user,
          sendTime: sendTime,
        },
        ...message,
      };
      return message;
    },
    // 添加消息到聊天列表
    appendMessage(message) {
      const curmsg = this.control.messageAll[this.control.currentContact.id];
      // 判断当前聊天室的聊天记录是否有这条消息，没有的就添加到队列中
      if (
        Array.isArray(curmsg) &&
        curmsg.findIndex((item) => item.id == message.id) === -1
      ) {
        this.control.appendMessage(message);
      }
    },
    async emitSend() {
      return new Promise(async (resolve, reject) => {
        const message = arguments[0];
        const target = arguments[1] || this.control.currentContact;
        const autoTimeout = arguments[2] || false;
        const isEncryptionMode = arguments[3] || false; //是否加密
        const { id } = message;
        const event = {
          success: async (translate) => {
            this._clearAutoTimeout(id);
            if (translate) {
              message.translate = this.$emoReplaceToImage(translate);
              this.control.scrollToBottom();
            }
            const lastMessage = {
              lastMessageContent: message.text
                ? message.text.replace(/<\/?.+?\/?>/g, "")
                : "",
              lastMessageType: message.type,
              lastMessageTime: message.sendTime,
            };
            this._changeStatus(id, "succeed", {
              ...target,
              ...lastMessage,
            });
            this.control.uploadFileCache[id] &&
              delete this.control.uploadFileCache[id];
            await this.control.updateContact(target.id, {
              ...lastMessage,
              isCatchMsg: false,
              unread: 0,
            });
            this.control.editorInputCache[target.id] = null;
            resolve();
          },
          error: () => {
            this._changeStatus(id, "failed");
            reject(id);
          },
        };
        let groupInfoMembers = this.control.getCurrentGroupInfo().members;

        // 清空需要置顶的会话ID
        this.$store.commit("setPinnedChatId", null);
        this.control.$emit(
          "send",
          message,
          target,
          event,
          groupInfoMembers,
          isEncryptionMode
        );
        // 一段时间过后socket没有回应，则展示发送失败
        if (autoTimeout) {
          this._bindAutoTimeout(id, event.error);
        }
      });
    },
    filterEmojiImage(content, trim = "&nbsp;") {
      if (!content) return content;
      return content.replace(
        // eslint-disable-next-line no-useless-escape
        new RegExp(`<img face-name=\"([^\"]*?)\" [^>]*>${trim}`, "gi"),
        "[!$1]"
      );
    },
    //发送消息前处理
    async send(content = this.$refs.textarea.innerHTML) {
      const sendToRobot =
        this.control.currentContact.isRobotChat &&
        this.control.$refs.robotMessageView;
      // 获取最新消息队列，判断上一个问题是否回答成功
      if (sendToRobot) {
        const last = this.control.getCurrentMessage("robot").slice(-1);
        // 如果上一条是还在等待，或者不是机器人回答，则不能发送下一条问题
        if (last[0].id == "waiting") {
          return;
        }
        if (this.robotInfo.validNums == 0) {
          this.$store.commit("setRobotChatDescription", {
            visible: true,
            type: "notice",
          });
          return;
        }
      }
      // 这里过滤掉引用显示的内容
      if (content.indexOf("inner-wrap") != -1) {
        let xx = content.split(`<div class="inner-wrap`);
        xx[1] && (content = xx[0]);
      }
      content = this.filterEmojiImage(content)
        .replace(/<div><br><\/div>/g, "\n")
        .replace(/^(<div>)/g, "")
        .replace(/\t/g, " ")
        .replace(/<\/div><div>/g, "\n")
        .replace(/<div>/g, "\n")
        .replace(/<\/div>/g, "\n")
        .replace(/<br\s*\/?>/gi, "\n")
        .replace(/&nbsp;/g, " ")
        //.replace(/(<br>\s*)+$/is, "")
        .replace(/<\/?.+?\/?>/g, "");
      if (!content.trim()) {
        return false;
      }
      try {
        content = content.trim();
      } catch (error) {}
      const textarea = this.$refs.textarea;
      textarea.innerHTML = "";
      this.$store.commit("setSubmitDisabled", true);
      this.$store.commit("setMeetingAddress", false);
      textarea.focus();
      //过滤表情图片
      content = this.filterEmojiImage(content);
      // 判断是不是超链接
      const isItURL = isURL(content.trim(), { require_protocol: false });
      // 判断是不是me聊
      const isNotRobotChat = !this.control.currentContact.isRobotChat;
      // 判断是不是会议链接
      const noMeetingLink = content.indexOf("/#/meeting/") == -1;
      // 判断是不是邮箱地址
      const isNotEmail = !isEmail(content.trim());
      if (
        isItURL &&
        isNotEmail &&
        isNotRobotChat &&
        noMeetingLink &&
        !this.secretChatStatus
      ) {
        this.$store.commit("setCopyLinkData", {
          type: "webUrl",
          text: content,
        });
        this.$store.commit("setCopyLinkDialogVisible", true);
        return;
      }
      this.isMemberStatusExpired(async () => {
        await this.realSend(content);
      });
    },
    // 验证过后开始发送
    async realSend(content) {
      const sendToRobot =
        this.control.currentContact.isRobotChat &&
        this.control.$refs.robotMessageView;
      const message = await this._previewSend({
        type: "text",
        text: content,
      });
      // 如果是机器人聊天，走机器人发送逻辑
      if (sendToRobot) {
        this.appendMessage(message);
        this.control.$refs.robotMessageView.send(message);
        return;
      }
      if (!message) return;
      // 这里判断是否是开启加密模式
      let isEncryptionMode =
        this.$store.state.imStore.otherPartyInfo.encryptStatus === 1;
      try {
        if (isEncryptionMode) {
          // 通过接口获取对方的公钥，进行消息加密
          let vipUserInfo = await vipUserStatus(this.control.currentContact.id);
          // 通过接口获取自己公钥， 进行消息加密
          let userInfo = await vipUserStatus(this.$store.getters.userInfo.id);
          message.text = "消息加密，请升级为会员查看";
          if (userInfo.data.data.publicKey) {
            message.encryptMessage = 1;
          } else {
            message.encryptMessage = 2;
          }
          message.encryptContentMe = await this.encryptedMessageMethod(
            content,
            vipUserInfo,
            userInfo,
            true
          );
          message.encryptContent = await this.encryptedMessageMethod(
            content,
            vipUserInfo,
            userInfo
          );
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "加密失败",
        });
      }
      this.appendMessage(message);
      this.emitSend(
        message,
        this.control.currentContact,
        true,
        isEncryptionMode
      );
      this.$store.commit("setAreaText", null);
      this.$store.commit("setQsData", {});
    },
    async encryptedMessageMethod(content, vipUserInfo, userInfo, isSelf) {
      // 对方的公钥
      let userPublicKey = vipUserInfo.data.data.publicKey;
      // 自己的公钥
      let localPublickKey = userInfo && userInfo.data.data.publicKey;
      // 如果对方没有公钥，则用自己的公钥进行加密
      if (!userPublicKey) {
        userPublicKey = localPublickKey;
      }
      // 通过加密后的公钥获取解密后pgp公钥
      const publicKey = await openpgp.readKey({
        armoredKey: isSelf ? localPublickKey : userPublicKey,
      });
      // 通过解密后pgp公钥开始pgp加密
      const encrypted = await openpgp.encrypt({
        message: await openpgp.createMessage({ text: content }),
        encryptionKeys: publicKey,
      });
      return encrypted;
    },
    //发送文件
    async upload({ file }) {
      this.isMemberStatusExpired(async () => {
        // 这里判断是否是开启加密模式
        let isEncryptionMode =
          this.$store.state.imStore.otherPartyInfo.encryptStatus === 1;
        const type = this._checkUploadType(file.type);
        const sendTargetUser = this.curUser;
        let message = await this._previewSend({
          type,
          fileSize: file.size,
          fileName: file.name,
        });
        if (!message) return;
        message = await this._setMessageByFile(message, file);
        const id = message.id;
        //为 message 设置 file 信息，提供给重新发送功能 || 显示文件信息使用
        this.control.uploadFileCache[id] = file;
        const progress = (fileUploadProgress) => {
          this.control.updateMessage({ id, fileUploadProgress });
        };
        const event = {
          error: () => {
            this._changeStatus(id, "failed");
            //释放 blob 资源, 释放之后火狐会出现问题
            //fileToURL.revoke(url);
          },
          success: (joinMessage) => {
            this._changeStatus(id, "succeed", sendTargetUser); //control.currentContact
            if (message.fileUploadProgress !== undefined) progress(100);
            message.onlineurl = joinMessage.url;
            this.emitSend(
              {
                ...message,
                ...joinMessage,
              },
              sendTargetUser
            );
          },
          progress: progress,
        };

        // this.$refs.uploader.abort(file);//取消上传
        if (isEncryptionMode) {
          // 通过接口获取对方的公钥，进行消息加密
          let vipUserInfo = await vipUserStatus(this.control.currentContact.id);
          let userInfo = await vipUserStatus(this.$store.getters.userInfo.id);
          if (userInfo.data.data.publicKey) {
            message.text = "消息加密，请升级为会员查看";
            message.encryptMessage = 1;
          } else {
            message.encryptMessage = 2;
          }
          message.encryptKey = await this.encryptedMessageMethod(
            AESKey,
            vipUserInfo,
            userInfo
          );
          message.encryptKeyMe = await this.encryptedMessageMethod(
            AESKey,
            vipUserInfo,
            userInfo,
            true
          );
          //获得文件名
          const file_name = file.name;
          message.encryptFileName = await this.encryptedMessageMethod(
            file_name,
            vipUserInfo,
            userInfo
          );
          message.encryptFileNameMe = await this.encryptedMessageMethod(
            file_name,
            vipUserInfo,
            userInfo,
            true
          );
          this.appendMessage(message);
          this.encode(file, message, this.control.currentContact, event);
        } else {
          this.appendMessage(message);
          this.control.$emit(
            "upload",
            message,
            this.control.currentContact, // sendTargetUser
            file,
            event
          );
        }
      });
    },
    async sendText(text, type) {
      const fieldType = type === "webUrl" ? "text" : type;
      const fieldName = type === "text" || type === "webUrl" ? "text" : "url";
      const message = await this._previewSend({
        type: fieldType,
        [fieldName]: text,
      });
      if (type === "webUrl") {
        message.urlIdentify = 1;
      }
      this.appendMessage(message);
      this.emitSend(message);
    },
    encode(file, message, currentContact, ev) {
      let file_mime = file.type;
      let file_name = file.name;
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const wordBuffer = ArrayBufferToWordArray(arrayBuffer);
        const encData = AESEncData(wordBuffer, AESKey, AESIv);
        const fileBlob = new Blob([encData], { type: "application/txt" });
        let encDatafile = new window.File([fileBlob], file_name, {
          type: file_mime,
        });
        this.control.$emit(
          "upload",
          message,
          currentContact, // sendTargetUser
          encDatafile,
          ev
        );

        // uploadFile(fileBlob, files[0].name);
        /** 这里直接下载下来 */
        // var blobURL = window.URL.createObjectURL(fileBlob);
        // const a = document.createElement('a')
        // a.href = blobURL
        // a.download = file.name
        // a.target = '_blank'
        // const clickEvent = new MouseEvent('click')
        // a.dispatchEvent(clickEvent)
        /** 这里直接下载下来 由于是通过AES加过密的文件，所以打不开 */

        /**  尝试进行AES解密文件，再下载，然后尝试打开 */
        // const DecwordBuffer = ArrayBufferToWordArray(encData);
        // const DecfileData = AESDecData(DecwordBuffer, AESKey, AESIv);
        // const decfileBlob = new Blob([DecfileData], { type: 'application/txt' });
        // var decblobURL = window.URL.createObjectURL(decfileBlob);
        // const a = document.createElement('a')
        // a.href = decblobURL
        // a.download = file.name
        // a.target = '_blank'
        // const clickEvent = new MouseEvent('click')
        // a.dispatchEvent(clickEvent)
      };
    },
    // 移除上传文件
    handleRemove() {
      // this.$refs.uploader.abort();//取消上传
      this.$message({ message: "成功取消", type: "success" });
    },
    async _setMessageByFile(msg, file) {
      msg.url = "";
      switch (msg.type) {
        case "image":
          msg.url = fileToURL.create(file) || (await fileToBase64(file));
          break;
        case "video":
          msg.url = fileToURL.create(file);
          break;
        case "file":
          msg.fileSize = file.size;
          msg.fileName = file.name;
          msg.fileUploadProgress = 0;
          break;
      }
      return msg;
    },
    _handEmoChoose(e, emo) {
      const textarea = this.$refs.textarea;
      this.modeIsAt = false;
      this._modeIsdrop = false;
      //对于文本消息，需要过滤表情、并移除HTML标签
      //var txt = content.data.replace(/\[!\w+\]/ig, '[' + helome.i18n.get('chat_page_tool_emotion') + ']');
      this._setCursorPosition(textarea, this.prevCursorPosition);
      this._insertElementToCursorAfter(
        textarea,
        `<img face-name="${emo.name}" src='${emo.src}' />&nbsp;`
      );
      this.$store.commit("setSubmitDisabled", false);
    },
    _inputBlur(e) {
      this.prevCursorPosition = this._getCursorPosition(e.target);
      this.handleInputOverflow();
    },
    // 监听输入事件
    _inputListen(e) {
      let submitDisabled = true;
      let val = e.target.innerHTML.trim();
      // 存一下这里输入的值
      this.$store.commit("setAreaText", val);
      // 这里是将输入的值存起来
      this.control.editorInputCache[this.control.currentContact.id] = val;
      // 当不是输入的内容只是回车或其他HTML标签的时候，将发送按钮设置为禁止发送
      if (val.replace(/<[^>]+>/g, "") !== "") {
        submitDisabled = false;
      }
      // let font_node = document.getElementsByTagName("font");
      // if (font_node.length !== 0) {
      //   font_node[0].style.color = "#000";
      // }
      this.$store.commit("setSubmitDisabled", submitDisabled);
    },
    // 输入监听
    input: _.throttle(
      async function(e) {
        // 加密模式无法使用@功能
        if (
          this.$store.state.imStore.otherPartyInfo.encryptStatus === 1 &&
          this.$store.getters.userInfo.vipStatus
        ) {
          return false;
        }
        if (e.data === "@") {
          this.modeIsAt = true;
          this._modeIsdrop = false;
          //单聊
          if (this.control.currentContact.isUser) {
            this.members = [this.control.currentContact];
          }
          //群聊
          if (this.control.currentContact.isGroup) {
            let groupInfo = this.control.getCurrentGroupInfo();
            let uesrInfoId = this.$store.getters.userInfo.id;
            this.members = groupInfo.members.filter(
              (item) => item.id !== uesrInfoId
            );
          }
          if (this.members.length > 0) {
            this.open();
          }
        } else {
          this.state.visible = false;
        }
      },
      10,
      { trailing: false }
    ),
    open() {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const { state } = this;
      // 缓存光标所在节点
      state.focusNode = selection.focusNode;
      // 缓存光标所在节点位置
      state.focusOffset = selection.focusOffset;
      // 光标所在位置
      const pos = range.getBoundingClientRect();
      // 显示选择框
      state.visible = true;
      // 输入框失去焦点
      // refAtInput.value.blur()
      // 设置弹窗位置
      this.$nextTick(() => {
        const x = pos.x > 75 ? pos.x - 75 : 0;
        const s = document.getElementsByClassName("atPopover")[0];
        const y = pos.y - s.offsetHeight - pos.height;
        s.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
      });
    },
    close() {
      const { state } = this;
      if (state.visible) {
        // 关闭选择框
        state.visible = false;
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        // 选中节点
        range.selectNode(state.focusNode);
        // 设置终点
        range.setEnd(state.focusNode, state.focusOffset);
        // 移动到终点
        range.collapse();
        // 聚焦输入框
        this.$refs.textarea.focus();
      }
    },
    //选择
    select(item) {
      // 关闭@弹出框
      const { state } = this;
      state.visible = false;
      const textarea = this.$refs.textarea;
      const element = document.createElement("SPAN");
      element.className = className;
      element.dataset.id = item.id;
      element.contentEditable = "false";
      element.innerHTML = `@${item.displayName}`;
      this._setCursorPosition(textarea, this.prevCursorPosition);
      this._insertElementToCursorAfter(textarea, element);
    },
    // 监听键盘点击事件
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        if (e1 && e1.keyCode == 38 && this.state.visible) {
          // e.preventDefault&&e.preventDefault();
        } else if (e1 && e1.keyCode == 40 && this.state.visible) {
          // 按下下箭头
          // e.preventDefault&&e.preventDefault();
        } else if (e1 && e1.keyCode == 13 && this.state.visible) {
          e.preventDefault && e.preventDefault();
          this.select(this.members[0]);
        }
      };
    },
    nameSfx(fileName) {
      const name = fileName;
      if (!name) return "";
      const arr = name.split(".");
      let str = arr[arr.length - 1].toUpperCase();
      if (str.length > 3) {
        str = str.substring(0, 3);
      }
      return str;
    },
    isImageType(str) {
      // toLowerCase() 将字符串转换为小写，返回一个新的字符串
      return (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(str.toLowerCase()) !== -1
      );
    },
    isVideo(str) {
      // toLowerCase() 将字符串转换为小写，返回一个新的字符串
      return ["mp4", "rmvb", "avi"].indexOf(str.toLowerCase()) !== -1;
    },
    confirmCopyLinkDialog(parms) {
      this.isMemberStatusExpired(async () => {
        let text = this.$store.state.imStore.copyLinkData.text;
        let type = this.$store.state.imStore.copyLinkData.type;
        if (parms.checkedImg) {
          this.sendText(text, type);
        }
        if (parms.checkedTxt) {
          this.sendText(text, "text");
        }
      });
    },
    //url参数为URL.createObjectURL(file.raw)，使用的是element ui的上传
    async _handlePaste(e) {
      e.preventDefault();
      const clipdata = e.clipboardData || e.originalEvent.clipboardData;
      //处理复制的文字
      let text = clipdata.getData("Text").replace(/&nbsp;/gi, " ");
      if (text) {
        // 这里判断是否是一个图片、视频类型的链接、如果是则需要做识别
        let sfxName = this.matchFileSuffixType(text);
        if (sfxName === "image" && !this.control.currentContact.isRobotChat) {
          let img = document.createElement("img");
          img.src = text;
          img.onload = async () => {
            this.$store.commit("setCopyLinkData", {
              type: "image",
              text,
            });
            this.$store.commit("setCopyLinkDialogVisible", true);
            img.remove();
          };
          img.onerror = async () => {
            this.fillInEditBox(text);
            img.remove();
          };
        } else if (
          sfxName === "video" &&
          !this.control.currentContact.isRobotChat
        ) {
          let video = document.createElement("video");
          video.src = text;
          video.oncanplaythrough = async () => {
            this.$store.commit("setCopyLinkData", {
              type: "video",
              text,
            });
            this.$store.commit("setCopyLinkDialogVisible", true);
            video.remove();
          };
          video.onerror = async () => {
            this.fillInEditBox(text);
            video.remove();
          };
        } else {
          this.fillInEditBox(text);
        }
      } else {
        //处理复制的图片
        if (!clipdata.items) return;
        if (this.control.currentContact.isRobotChat) return;
        const file = Array.from(clipdata.items)
          .find((item) => item.kind == "file")
          .getAsFile();
        if (!file.name) file.name = "image.png";
        let nameSfx = this.nameSfx(file.name);
        let isImageType = this.isImageType(nameSfx);
        let isVideo = this.isVideo(nameSfx);
        let fileUrl = this.formartNewFileIcon(nameSfx);
        let imgUrl = fileToURL.create(file);
        let isFile = fileUrl && fileUrl.indexOf("file.") !== 0;
        let req;
        // 图片类型
        if (isImageType) {
          req = await this.$confirm(
            <div style="max-height:500px;overflow:hidden">
              <i
                class="el-icon-s-promotion"
                style={{
                  fontSize: "30px",
                  margin: "10px 0 20px 0",
                }}
              />
              <img style={{ width: "100%", userSelect: "none" }} src={imgUrl} />
            </div>,
            "",
            {
              customClass: "message-box__copyimage-tip",
              confirmButtonText: " ",
              cancelButtonText: " ",
              cancelButtonClass: "el-icon-close",
              confirmButtonClass: "el-icon-check",
              center: true,
            }
          ).catch(() => {});
        } else if (isVideo) {
          req = await this.$confirm(
            <div style="max-height:500px;overflow:hidden">
              <i
                class="el-icon-s-promotion"
                style={{
                  fontSize: "30px",
                  margin: "10px 0 20px 0",
                }}
              />
              <video
                style={{ width: "100%", userSelect: "none" }}
                src={imgUrl}
              />
            </div>,
            "",
            {
              customClass: "message-box__copyimage-tip",
              confirmButtonText: " ",
              cancelButtonText: " ",
              cancelButtonClass: "el-icon-close",
              confirmButtonClass: "el-icon-check",
              center: true,
            }
          ).catch(() => {});
        } else {
          req = await this.$confirm(
            <div style="max-height:500px;overflow:hidden">
              <i
                class="el-icon-s-promotion"
                style={{
                  fontSize: "30px",
                  margin: "10px 0 20px 0",
                }}
              />
              <div class="copy-message-file">
                {isFile && (
                  <div class="image-box">
                    <el-image class="fileIcon" src={fileUrl} />
                  </div>
                )}
                {!isFile && (
                  <div class="imelink-message-file__icon">
                    <div class="folder-box">
                      <div>
                        <i class="el-icon-folder"></i>
                        <b>{nameSfx}</b>
                      </div>
                    </div>
                  </div>
                )}
                <div class="imelink-message-file__content">
                  <p class="imelink-message-file__name">{file.name}</p>
                  <p class="imelink-message-file__foot imelink__foot">
                    <small> {file.size ? toBytesSize(file.size) : ""}</small>
                  </p>
                </div>
              </div>
            </div>,
            "",
            {
              customClass: "message-box__copyimage-tip",
              confirmButtonText: " ",
              cancelButtonText: " ",
              cancelButtonClass: "el-icon-close",
              confirmButtonClass: "el-icon-check",
              center: true,
            }
          ).catch(() => {});
        }
        if (req == "confirm") this.upload({ file });
      }
    },
    // 填充到编辑框
    fillInEditBox(text) {
      text = text.replace(/<[^>]*>/gi, "");
      this.$store.commit("setSubmitDisabled", false);
      const range = window.getSelection().getRangeAt(0);
      const textNode = document.createTextNode(text);
      const offset = textNode.length;
      range.deleteContents();
      range.insertNode(textNode);
      this.$refs.textarea.innerHTML = this.$refs.textarea.innerHTML.replace(
        /\r\n/gi,
        "<br/>"
      );
      range.setStart(textNode, offset);
      range.setEnd(textNode, offset);
    },
    _checkUploadType(type) {
      const image = ["image/jpeg", "image/png", "image/gif"];
      const video = ["video/mp4"];
      if (image.includes(type)) return "image";
      if (video.includes(type)) return "video";
      return "file";
    },
    //在光标之后插入元素
    _insertElementToCursorAfter(cursorElem, elem, bleean) {
      bleean && (this.modeIsAt = false);
      const { state } = this;
      let range, node;
      if (!cursorElem.hasfocus) {
        cursorElem.focus();
      }
      if (window.getSelection && window.getSelection().getRangeAt) {
        range = window.getSelection().getRangeAt(0);
        if (this.modeIsAt) {
          try {
            if (!this._modeIsdrop) {
              range.setStart(state.focusNode, state.focusOffset - 1);
              range.setEnd(state.focusNode, state.focusOffset);
              // 删除输入的 @ 符
              range.deleteContents();
            }
            range.insertNode(elem);
            node = range.createContextualFragment("&nbsp;");
            range.collapse(false);
            range.insertNode(node);
            range.collapse(false);
            let j = window.getSelection();
            j.removeAllRanges();
            j.addRange(range);
          } catch (error) {}
        } else {
          range.collapse(false);
          node = range.createContextualFragment(elem);
          let c = node.lastChild;
          range.insertNode(node);
          if (c) {
            range.setEndAfter(c);
            range.setStartAfter(c);
          }
          let j = window.getSelection();
          j.removeAllRanges();
          j.addRange(range);
        }
      } else if (document.selection && document.selection.createRange) {
        document.selection.createRange().pasteHTML(elem);
      }
    },
    //获取光标位置
    _getCursorPosition(element) {
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },
    //设置光标位置
    _setCursorPosition(element, pos) {
      const createRange = (node, chars, range) => {
        if (!range) {
          range = document.createRange();
          range.selectNode(node);
          range.setStart(node, 0);
        }
        if (chars.count === 0) {
          range.setEnd(node, chars.count);
        } else if (node && chars.count > 0) {
          if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
              chars.count -= node.textContent.length;
            } else {
              range.setEnd(node, chars.count);
              chars.count = 0;
            }
          } else {
            for (let lp = 0; lp < node.childNodes.length; lp++) {
              range = createRange(node.childNodes[lp], chars, range);
              if (chars.count === 0) {
                break;
              }
            }
          }
        }
        return range;
      };
      if (pos >= 0) {
        const selection = window.getSelection();
        const range = createRange(element.parentNode, {
          count: pos,
        });
        if (range) {
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    //拖动的元素进入了
    _dragenter(e) {
      e = e || event;
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }
    },
    _ondragover(e) {
      e = e || event;
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }
    },
    _dragleave() {},
    _drop(e) {
      try {
        const textarea = this.$refs.textarea;
        this.modeIsAt = true;
        this._modeIsdrop = true;
        let ele = JSON.parse(e.dataTransfer.getData("text"));
        const element = document.createElement("SPAN");
        element.className = className;
        element.dataset.id = ele.id;
        element.contentEditable = "false";
        element.innerHTML = `@${ele.displayName}`;
        this._setCursorPosition(textarea, this.prevCursorPosition);
        this._insertElementToCursorAfter(textarea, element);
        this.$store.commit("setSubmitDisabled", false);
      } catch (err) {}
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

.el-button--encring{
  opacity: 1!important;
  background: linear-gradient(132deg,#f7c988 7%, #d69850 92%) !important;
  color:#fff!important;
}
.el-button--encring.is-disabled{
  background: linear-gradient(132deg,#f7c988 7%, #d69850 92%) !important;
  color:#fff!important;
  opacity: 0.6!important;
}

.__at_span {
  color: #52BD68 !important;
}

.atPopover {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  right: auto !important;
  bottom: auto !important;
  max-height: 265px;
  overflow-y: auto;
  padding: 0;

  .popper__arrow {
    display: none;
  }

  /* 修改滚动条样式 */
  &::-webkit-scrollbar {
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #333;
  }

  &::-webkit-scrollbar-corner {
    background: #179a16;
  }

  .selected {
    background: #ececee !important;
  }

  .bb {
    padding: 10px 20px;
    box-sizing: border-box;
    user-select: none;

    &:first-child {
      background: #ececee !important;
    }

    &:not(&:first-child):hover {
      background: #f3f3f3 !important;
    }
  }

  .imelink-popover-list {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;

    .me-avatar {
      margin-right: 10px;
    }
  }
}

cross-padding = 15px;

.fs22 {
  font-size: 22px;
}

.imelink-container--fullscreen {
  .imelink-editor {
    max-height: 700px;
  }
}

+b(imelink-editor) {
  height: 190px;
  display: flex;
  flex-direction: column;
  min-height: 190px;
  max-height: 460px;
  background: imelink-editor-background;
  position: relative;
  overflow: hidden;

  +e(resize) {
    position: absolute;
    top: -5px;
    background: transparent;
    height: 10px;
    width: 100%;
    cursor: s-resize;
  }

  +e(header) {
    border-top: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 39px;
    max-height: 39px;
    padding: 3px 5px 3px;
    // padding-left: cross-padding <= 11 ? 0 : (((((((((((((((((((((((((((((cross-padding - 11)))))))))))))))))))))))))))));
    font-size: 20px;
  }

  .imelink-editor__headerleft, .imelink-editor__headerright {
    display: flex;
    flex-direction: row;
  }

  +e(action) {
    padding: 0px 12px;
    cursor: pointer;
    text-align: center;
    color: imelink-editor-tool-color;
    transition: color ease-in-out 0.3s;
    font-size: 22px;

    &:hover {
      color: imelink-editor-tool-active-color;
    }

    +m(right) {
      float: right;
    }
  }

  +e(action-txt) {
    font-size: 12px;
    position: relative;
    top: -4px;
    padding-left: 4px;
  }

  +e(main) {
    flex: auto;
    overflow: hidden;
    height: 100%;
  }

  +e(footer) {
    text-align: right;
    padding: 0 cross-padding;
  }

  +e(textarea) {
    font-size: 14px;
    line-height: 22px;
    padding: 0 cross-padding;
    overflow-y: auto;
    height: 100%;
    flex: 1;
    outline: none;
    scrollbar-light();
    word-wrap: break-word;

    img {
      vertical-align: middle;
      height: 22px;
      position: relative;
      top: -2px;
    }
  }

  +e(submit-tip) {
    font-size: 12px;
    vertical-align: middle;
    color: #aaa;
    padding-right: 20px;
  }

  +e(submit) {
    .el-button {
      padding: 4px 5px;
      font-size: 12px;

      span {
        display: flex;
        flex-flow: row;
        align-items: center;
      }
    }

    i {
      font-size: 18px;
    }
  }
}

.message-box__copyimage-tip {
  .el-message-box__header {
    display: none;
  }
}
</style>
<style lang="stylus" scoped>
.imelink-editor__headerleft
  .remainingTimes
    font-size: 10px;
    color: #9A9A9C;
    display: flex;
    align-items: center;
    .count
      margin: 0 2px 0 10px;
    .unit
      margin-right: 5px;
    .iconStyle
      font-size: 16px;
      cursor: pointer;
.multimediaBox {
  display: flex;
  align-items: center;
  justify-content: center;

  .multimediaIcon {
    width: 23px;
    height: 23px;
    background: url('../../../assets/images/multiplayer/mediaUnselected.png') no-repeat;
    background-size: 100% 100%;

    &:hover {
      background: url('../../../assets/images/multiplayer/mediaSelected.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}

// 自定义button样式用于重复点击处理
.mk_button {
  background: transparent !important;
  border: 0;
}


// 复制粘贴过来的文件的样式
.copy-message-file{
  background:#e4f0fc !important;
  padding:15px;
  display:flex;
  border-radius:6px;
  .image-box{
    margin-right: 10px;
    white-space: nowrap;
  }
  .fileIcon {
    width: 50px;
    height: 54px;
}
}

.copyimage,.copyText{
  display:flex;
  .el-radio{
    margin-right:10px;
  }
  img{
    max-height:200px;
    object-fit: cover;
  }
  .text{
    word-break: break-all;
    width:100%;
  }
  .el-checkbox{
    margin-right:20px;
    :deep .el-checkbox__inner{
      border-radius:50%;
      width: 18px;
      height: 18px;
      &::after {
        left: 6px;
        width: 4px;
        height: 10px;
      }
    }
  }
}
.copyText{
  margin-top:10px;
}
</style>
